import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("@/views/customer/index.vue"),
    meta: {
      title: "客户",
    },
  },
  {
    path: "/customer/management",
    name: "CustomerManagement",
    component: () => import("@/views/customer/management/index.vue"),
    meta: {
      title: "客户管理",
    },
  },
  {
    path: "/customer/passwordQuery",
    name: "PasswordQuery",
    component: () => import("@/views/customer/passwordQuery/index.vue"),
    meta: {
      title: "客户查询",
    },
  },
  {
    path: "/staff",
    name: "PasswordQuery",
    component: () => import("@/views/staff/index.vue"),
    meta: {
      title: "员工查询",
    },
  },
  {
    path: "/customer/details",
    name: "Customerdetails",
    component: () => import("@/views/customer/management/details.vue"),
    meta: {
      title: "客户详情",
    },
  },
  {
    path: "/customer/headDetails",
    name: "CustomerHeadDetails",
    component: () => import("@/views/customer/management/headDetails.vue"),
    meta: {
      title: "头疗详情",
    },
  },
  {
    path: "/customer/drugDetails",
    name: "CustomerDrugDetails",
    component: () => import("@/views/customer/management/drugDetails.vue"),
    meta: {
      title: "裹药仓详情",
    },
  },
  {
    path: "/customer/register",
    name: "Register",
    component: () => import("@/views/customer/register/index.vue"),
    meta: {
      title: "新客登记",
    },
  },
  {
    path: "/customer/arrive",
    name: "CustomersArrive",
    component: () => import("@/views/customer/arrive/index.vue"),
    meta: {
      title: "客户到店",
    },
  },
  {
    path: "/customer/arrive/selectItem",
    name: "ArriveSelectItem",
    component: () => import("@/views/customer/arrive/selectItem.vue"),
    meta: {
      title: "选择项目",
    },
  },
  {
    path: "/customer/arrive/headFrom",
    name: "ArriveHeadFrom",
    component: () => import("@/views/customer/arrive/headFrom.vue"),
    meta: {
      title: "头疗报告填写",
    },
  },
  {
    path: "/customer/arrive/drugFrom",
    name: "ArriveDrugFrom",
    component: () => import("@/views/customer/arrive/drugFrom.vue"),
    meta: {
      title: "裹药仓报告填写",
    },
  },
  {
    path: "/staff/detail",
    name: "Staff",
    component: () => import("@/views/staff/detail.vue"),
    meta: {
      title: "员工",
    },
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("@/views/project/index.vue"),
    meta: {
      title: "项目",
    },
  },
  {
    path: "/collection",
    name: "Collection",
    component: () => import("@/views/collection/index.vue"),
    meta: {
      title: "收款",
    },
  },
  {
    path: "/we",
    name: "We",
    component: () => import("@/views/we/index.vue"),
    meta: {
      title: "我们",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
