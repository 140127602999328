import storage from "@/libraries/storage";

export default {
  namespaced: true,
  state: {
    currentCustomer: storage.get("currentCustomer"),
  },
  mutations: {
    set(state, { key, value }) {
      state[key] = value;
      storage.set(key, value);
    },
  },
  actions: {},
};
