<template>
  <div id="app"><router-view /></div>
</template>
<script>
export default {
  created() {
    document.title = "沐槿理疗";
  },
};
</script>
<style lang="scss">
@import "~@/assets/styles/base";
</style>
