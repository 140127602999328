<template>
  <section class="layout">
    <aside class="layout-aside">
      <nav class="layout-nav">
        <el-tooltip
          v-for="(item, key) in menus"
          :key="key"
          effect="light"
          :content="item.label"
          placement="right"
        >
          <span
            @click="navigateTo(item)"
            :class="{ active: item.label === menu.label }"
          >
            <i class="iconfont" :class="item.icon"></i>
            <p>{{ item.label }}</p>
          </span>
        </el-tooltip>
      </nav>
    </aside>
    <main class="layout-main">
      <header class="layout-header">
        <div class="left">
          <i class="iconfont icon-fanhui" v-show="hasBack" @click="goBack"></i>
          <slot name="header-left"></slot>
        </div>
        <h4 class="layout-header-title">{{ title }}</h4>
        <div class="layout-header-right">
          <slot name="editIcon"></slot>
          <i
            class="iconfont icon-tuichu"
            style="color: #d81e06"
            @click="logout"
          ></i>
        </div>
      </header>
      <main class="layout-body">
        <slot></slot>
      </main>
    </main>
  </section>
</template>
<script>
import storage from "@/libraries/storage";
export default {
  name: "Layout",
  props: {
    title: {
      type: String,
      default: "",
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menus: [
        { label: "客户", icon: "icon-kehu1", routePath: "/customer" },
        {
          label: "员工",
          icon: "icon-yuangong",
          routePath: "/staff",
        },
        { label: "项目", icon: "icon-xiangmu", routePath: "/project" },
        { label: "收款", icon: "icon-shoukuan", routePath: "/collection" },
        { label: "我们", icon: "icon-women", routePath: "/we" },
      ],
      menu: { label: "登录", icon: "icon-yuangong", routePath: "/login" },

      user: storage.get("user"),
    };
  },
  mounted() {
    this.setMenu();
  },
  methods: {
    logout() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          storage.delete("user");
          storage.delete("accessToken");
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },

    goBack() {
      this.$router.back();
    },
    navigateTo(data) {
      this.menu = data;
      if (this.$route.path !== data.routePath) {
        this.$router.push(data.routePath);
      }
    },
    setMenu() {
      const path = "/" + this.$route.path.split("/")[1];
      this.menu = this.menus.find((item) => item.routePath === path);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-divider--horizontal {
  margin: 0;
}
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f5f5f5;

  &-aside {
    width: 80px;
    height: 100%;
    flex-shrink: 0;
    background-color: #ffffff;
  }

  &-nav {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      width: 40px;
      height: 40px;
      color: #555555;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 35px 0;
      border-radius: 4px;
      cursor: pointer;

      .iconfont {
        font-size: 39px;
      }

      p {
        line-height: 20px;
        font-size: 14px;
      }

      &.active {
        color: #a67c52;
      }
    }
  }

  &-main {
    flex: 1;
    height: 100%;
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  &-header {
    width: 100%;
    height: 50px;
    background-color: #fff;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    i {
      font-size: 24px;
    }
    &-title {
      font-size: 16px;
    }
    &-right {
      i {
        margin: 0 10px;
      }
    }
  }
  &-body {
    width: 100%;
    flex: 1;
    overflow: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(@/assets/images/background.png);
    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .back {
      margin-right: 20px;
    }

    .title {
      font-size: 22px;
      font-weight: bold;
      margin-right: 20px;
    }
  }
}
</style>
