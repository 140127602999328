import Vue from "vue";
// import vConsole from "../vconsole";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Layout from "@/components/layout";
import ElementUI from "element-ui";
import "./assets/iconfont/iconfont.css";
import "./assets/styles/element-variables.scss";
import Vant from "vant";
import "vant/lib/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.directive("noMoreClick", {
  inserted(el, binding) {
    el.addEventListener("click", (e) => {
      el.classList.add("is-disabled");
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove("is-disabled");
      }, 3000); //我这里设置的是2000毫秒也就是2秒
    });
  },
});
Vue.use(VueAwesomeSwiper);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.component("layout", Layout);
Vue.config.productionTip = false;
new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
